
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PrimaryButton',
  props: [
    'data',
    'isSelected',
  ],
});
