
import { defineComponent } from 'vue';
import globals from '../../helpers/globals';
import PrimaryButton from '../../components/PrimaryButton.vue';
import Product from '../../types/Product';
import SegmentDataHelper from '../../helpers/SegmentDataHelper';
import FinanceTypeDataHelper from '../../helpers/FinanceTypeDataHelper';
import ProductDataHelper from '../../helpers/ProductDataHelper';
import SimulationDataService from '../../services/SimulationDataService';
import StepData from '../../types/StepData';
import UndefinedResourceError from '../../types/exceptions/UndefinedResourceError';

export default defineComponent({
  name: 'ProductSelection',
  mixins: [
    globals,
  ],
  components: {
    PrimaryButton,
  },
  data() {
    return {
      items: [] as Product[],
    };
  },
  created() {
    const vm = this;

    /* Try to set profile if authenticated */
    this.setProfile(this.$store);

    /* LOAD DATA WHEN COMPONENT IS CREATED */
    this.$store.dispatch('isLoading', true);

    this.load((data: any) => {
      /* GET OBJECT FROM STORE */
      vm.$store.dispatch('setSelectedSegment', SegmentDataHelper.getObjectFromStore(vm.$store, vm.$route.params.segment as string));
      vm.$store.dispatch('setSelectedFinanceType', FinanceTypeDataHelper.getObjectFromStore(vm.$store, vm.$route.params.financeType as string));

      if (Object.keys(vm.$store.state.selected.segment).length === 0 || Object.keys(vm.$store.state.selected.financeType).length === 0) {
        throw new UndefinedResourceError();
      }

      vm.$store.dispatch('setDataIntoStep', { step: 'IsCustomerSelection', data: { isCustomer: localStorage.isCustomer } } as StepData);

      vm.items = data;

      vm.init();
    });
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* FLAG STEP AS UNCOMPLETED */
      this.setStepAsCompleted(this, false);

      /* MOVE PROGRESS BAR TO VALUE */
      this.progress(this);

      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', true);
      this.$store.dispatch('toggleSideBar', true);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    /* LOAD DATA THROUGH APIS */
    load(callback: any) {
      ProductDataHelper.prepareComponentData(
        this.$store,
        this.$route.params.segment as string,
        this.$route.params.financeType as string,
        callback,
      );
    },
    setSelectedProductInStore(target: Product) {
      const vm = this;

      if (!('children' in target) || target.children.length === 0) {
        // Load recaptcha token
        this.recaptcha(this)
          .then((resp: any) => {
            this.setStepAsCompleted(this, true);

            localStorage.product = target.pid;

            if (!this.$store.state.selected.segment.has_simulation) {
              SimulationDataService.execute(this.$store.state.selected.segment, target, {
                is_customer: localStorage.isCustomer,
                recaptcha: vm.$store.state.recaptcha,
              }).then((response: any) => {
                // vm.$store.dispatch('setSimulationAsCompleted', true);

                this.goToNextScreen(this, {
                  simulation: response.data.simulation,
                });
              }).catch((e) => { /* */ });
            } else {
              this.goToNextScreen(this, {
                segment: this.$store.state.selected.segment.pid,
                financeType: this.$store.state.selected.financeType.pid,
                product: target.pid,
              });
            }
          });
      } else {
        this.items = target.children;
      }
    },
    isSelected(pid: string) {
      return localStorage.product && localStorage.product === pid;
    },
  },
});
